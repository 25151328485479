.container {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .formContainer {
    width: 480px;
    height: 478px;
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .form {
    height: 374px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  

  .link {
    text-decoration: none;
    color: #4c4cff;
  }