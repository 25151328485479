.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
}
.container {
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
