.container {
  width: 272px;
  height: 208px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.priceContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text {
  margin: 0;
  text-align: center;
}

.wrapper {
  position: relative;
  cursor: pointer;
  background-color: none;
  text-decoration: none;
  color: inherit;
}

.wrapper:active {
  background: #1c1c21;
}

.wrapper:hover {
  background: #1c1c21;
}
.counter {
  position: absolute;
  right: 0;
  top: 0;
}
