.form {
  width: 480px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 200px;
  gap: 24px;
}
