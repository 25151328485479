.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 640px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background: #1c1c21;
  z-index: 99;
  border-radius: 40px;
}

.hideOverflowInBody {
  overflow: hidden;
}

.info {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.backDoor {
  cursor: pointer;
}
