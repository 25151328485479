:root {
  --background: #1c1c21;
  --background-alt: #131316;
  --background-element: #2f2f37;
  --offset-base-variable: 2px;
  --offset-base-size: 4px;
  --top-constructor-item-border-radius: 88px 88px 40px 40px;
  --bottom-constructor-item-border-radius: 40px 40px 88px 88px;
  --common-border-radius: 64px;
  --common-border-radius-s: 40px;
  --text-primary-color: white;
  --text-inactive-color: #8585ad;
  --colors-interface-accent: #4c4cff;
  --colors-interface-error: #e52b1a;
  --colors-interface-success: #00cccc;
  --colors-button-inactive: #3a3a55;
  scrollbar-color: #2f2f37 #8585ad;
  scrollbar-width: thin;
  --common-transition: all 0.3s ease-in;
}

.container {
  padding: 16px 24px;
  display: inline-block;
  border-radius: 40px;
  background: #1c1c21;
  max-width: 536px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.top {
  border-radius: 88px 88px 40px 40px;
}

.bottom {
  border-radius: 40px 40px 88px 88px;
}

.text {
  color: #00cccc;
}
