.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  text-decoration: none;
  color: inherit;
}
