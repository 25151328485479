.container {
  width: 600px;
  height: 656px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.middle {
  width: 600px;
  height: 465x;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-color: #8585ad #2f2f37;
}

.itemContainer {
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.placeholder {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.droppable {
  background: #1c1c21;
  border-radius: 40px;
}

.middle::-webkit-scrollbar {
  width: 8px;
}

.middle::-webkit-scrollbar-track {
  background: #2f2f37;
}

.middle::-webkit-scrollbar-thumb {
  background: #8585ad;
}
